import { graphql } from "gatsby"
import PostComponent from "../components/post"

export default PostComponent

export const query = graphql`
  query($slug: String!, $formatString: String!) {
    allMdx(filter: {frontmatter: {date: {ne: null}}, childrenMdxPost: {elemMatch: {slug: {eq: $slug}}}}) {
      nodes {
        body
        excerpt
        timeToRead
        childrenMdxPost {
          slug
        }
        frontmatter {
          date(formatString: $formatString)
          tags
          title
          banner {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          weight
          recipe {
            title
            ingredients {
              name
              percentage
            }
          }
        }
      }
    }
  }
`