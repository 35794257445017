import React, { useState, useRef, useEffect } from "react"
import { styled } from "@material-ui/core"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"

const Table = styled("table")({
  width: "100%",
  fontSize: "1rem",
  fontFamily: '"Cooper Hewitt","Helvetica neue",helvetica,sans-serif',
  fontWeight: "400",
  background: "rgba(251, 233, 231, 0.5)",
  border: "none",
  padding: "2rem",
  borderCollapse: "collapse",
  margin: "0 auto",
  marginTop: "1rem",
  "& td": {
    paddingLeft: "16px",
  },
  "& tr": {
    borderBottom: "10px solid transparent",
  },
  "& th": {
    textAlign: "left",
    paddingLeft: "16px",
    verticalAlign: "top",
  },
  "& thead": {
    background: "white",
    borderBottom: "solid 3px #fbe9e7",
  },
})

const CalcInputs = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "1rem",
  gap: "1rem",
  "& input": {
    width: "7rem",
  },
})

const RecipeCalculator = ({ recipe, weight }) => {
  console.log(recipe)

  let percentages = []

  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals)
  }

  const [currentWeight, setCurrentWeight] = useState(weight)
  const [measurementMethod, setMeasurementMethod] = useState("g")

  useEffect(() => {
    ref.current.value = currentWeight
  }, [currentWeight])

  const ref = useRef(null)

  const changeMeasurement = e => {
    setMeasurementMethod(e.target.value)

    if (measurementMethod === "g") {
      setCurrentWeight(round(ref.current.value / 28.35, 0))
    } else {
      setCurrentWeight(round(ref.current.value * 28.35, 0))
    }
  }

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Phase</th>
            <th>Ingredient</th>
            <th>Percent (%)</th>
            <th>Weight ({measurementMethod === "g" ? "g" : "oz"})</th>
          </tr>
        </thead>

        {recipe.map(item => (
          <>
            {item.ingredients.map((ingredient, i, array) => {
              percentages.push(ingredient.percentage)
              return (
                <tr>
                  {i === 0 && <th rowspan={array.length}>{item.title}</th>}
                  <td>{ingredient.name}</td>
                  <td>{ingredient.percentage}</td>
                  <td>
                    {round((currentWeight * ingredient.percentage) / 100, 2)}
                  </td>
                </tr>
              )
            })}
          </>
        ))}
      </Table>
      <CalcInputs>
        {/* <input type="number"  onChange={(e) => setCurrentWeight(e.target.value)} ref={ref}></input> */}
        {/* <select name="measurement" id="measurement" onChange={(e) => changeMeasurement(e)}>
          <option value="metric">Total weight (g)</option>
          <option value="imperial">Total weight (oz)</option>
        </select> */}
        <TextField
          id="standard-number"
          type="number"
          onChange={e => setCurrentWeight(e.target.value)}
          value={currentWeight}
        />
        <Select
          labelId="Measurement"
          id="measurement"
          onChange={e => changeMeasurement(e)}
          value={measurementMethod}
          ref={ref}
        >
          <MenuItem value={"g"}>Total weight (g)</MenuItem>
          <MenuItem value={"oz"}>Total weight (oz)</MenuItem>
        </Select>
      </CalcInputs>
      {percentages.reduce((a, b) => a + parseFloat(b), 0).toFixed(2) != 100
        ? alert(
            "Percentage is not 100%, it is " +
              percentages.reduce((a, b) => a + parseFloat(b), 0).toFixed(2)
          )
        : console.info(
            "Percentage is " +
              percentages.reduce((a, b) => a + parseFloat(b), 0).toFixed(2)
          )}
    </div>
  )
}

export default RecipeCalculator
